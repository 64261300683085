<template>
  <div>
    <v-dialog
      v-model="isTaskFormActive"
      persistent
      max-width="75%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Task Details</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="PatientTaskForm"
          >
            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <patient-select
                  :patient="patientTaskDTO.patient"
                  :readonly="!isEdit"
                  @patient-select="updatePatientId"
                ></patient-select>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <task-type-select
                  :multiple="false"
                  :task-type="patientTaskDTO.patientTaskType"
                  :readonly="!isEdit"
                  @task-type-select="updateType"
                ></task-type-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <v-text-field
                  v-model="patientTaskDTO.taskName"
                  label="Task Name"
                  hint="Review Patient Status"
                  outlined
                  dense
                  :readonly="!isEdit"
                  :rules="[required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <v-text-field
                  v-model="patientTaskDTO.taskDescription"
                  label="Task Description"
                  hint="Complete CIWA Form for Patient"
                  outlined
                  dense
                  :readonly="!isEdit"
                  :rules="[required]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="6"
              >
                <v-menu
                  ref="menuref"
                  v-model="menuSD"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="patientTaskDTO.startDate"
                      :prepend-icon="icons.mdiCalendar"
                      label="Start Date"
                      hint="MM/DD/YYYY"
                      :readonly="!isEdit"
                      :rules="[required]"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-if="isEdit"
                    ref="picker"
                    v-model="patientTaskDTO.startDate"
                    color="primary"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col
                cols="12"
                lg="6"
              >
                <v-menu
                  ref="menuref"
                  v-model="menuDD"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="patientTaskDTO.dueDate"
                      :prepend-icon="icons.mdiCalendar"
                      label="Due Date"
                      hint="MM/DD/YYYY"
                      :rules="[required]"
                      :readonly="!isEdit"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-if="isEdit"
                    ref="picker"
                    v-model="patientTaskDTO.dueDate"
                    :min="new Date().toISOString().substr(0, 10)"
                    color="primary"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                lg="4"
              >
                <form-select
                  v-model="patientTaskDTO.patientFormId"
                  @form-select="updateFormSelect"
                ></form-select>
              </v-col>

              <v-col
                cols="12"
                lg="6"
              >
                <v-radio-group
                  v-model="patientTaskDTO.status"
                  row
                  :readonly="!isEdit"
                >
                  <v-radio
                    v-for="(status, index) in taskStatus"
                    :key="index"
                    :label="status"
                    :value="status"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <v-col
                cols="12"
                lg="2"
              >
                <v-switch
                  v-model="patientTaskDTO.isActive"
                  color="primary"
                  :value="patientTaskDTO.isActive != null ? patientTaskDTO.isActive : true"
                  :label="patientTaskDTO.isActive ? 'Active' : 'Inactive'"
                  :readonly="!isEdit"
                >
                </v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="error darken-1"
                  type="reset"
                  text
                  @click="closeForm()"
                >
                  Close
                </v-btn>
                <v-btn
                  v-if="isEdit"
                  color="primary darken-1"
                  text
                  @click="submitForm(patientTaskDTO)"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { ref, onUnmounted } from '@vue/composition-api'
import taskService from '@/services/TaskService'
import useTaskList from '@/store/useTaskService'
import PatientSelect from '@/components/patient/PatientSelect.vue'
import FormSelect from '@/components/patient/FormSelect.vue'
import TaskTypeSelect from '@/components/task/TaskTypeSelect.vue'
import { mdiClose, mdiCalendar } from '@mdi/js'

export default {
  components: {
    FormSelect,
    PatientSelect,
    TaskTypeSelect,
  },
  model: {
    prop: 'isTaskFormActive',
    event: 'update:is-task-form-active',
  },
  props: {
    isTaskFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    patientTaskDTO: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    console.log('PatientTask', props.patientTaskDTO)
    const TASK_APP = 'app-task'

    // Register module
    if (!store.hasModule(TASK_APP)) {
      store.registerModule(TASK_APP, taskService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TASK_APP)) store.unregisterModule(TASK_APP)
    })

    const { postPatientTask, taskStatus } = useTaskList()

    const patientTaskForm = ref(null)
    const menuSD = ref(false)
    const menuDD = ref(false)
    const menuref = ref(null)

    const save = d => {
      menuref.value.save(d)
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update-dialog', 'render')
    }

    return {
      taskStatus,
      patientTaskForm,
      closeForm,
      refreshParent,
      required,
      menuSD,
      menuDD,
      menuref,
      save,

      postPatientTask,

      icons: {
        mdiClose,
        mdiCalendar,
      },
    }
  },
  methods: {
    validate() {
      if (this.patientTaskForm.value.validate(this.patientTaskDTO)) {
        return true
      }

      return false
    },

    submitForm() {
      //console.log('posting', this.patientTaskDTO)

      this.postPatientTask(this.patientTaskDTO)

      this.closeForm()
      this.refreshParent()
    },
    updateFormSelect(form) {
      this.patientTaskDTO.formStyleId = form.formStyleId
      //console.log('Form Select update', form, this.patientTaskDTO)
    },
    updatePatientId(patientDTO) {
      //console.log(patientDTO)
      this.patientTaskDTO.patientId = patientDTO.id
      //console.log(this.patientTaskDTO)
    },
    updateType(taskType) {
      this.patientTaskDTO.TaskTypeId = taskType.id
    },
  },
}
</script>
