<template>
  <div id="task-list">
    <!-- dialog -->
    <v-card>
      <delete-confirmation
        v-model="isDeleteDialogActive"
        :selected-item="selectedItem"
        @delete-confirmation="submitDelete"
        @refetch-data="refreshPage"
      ></delete-confirmation>

      <task-add-edit
        v-if="isTaskFormActive"
        :is-task-form-active="isTaskFormActive"
        :patient-task-d-t-o="selectedItem"
        :is-edit="isEdit"
        @update-dialog="updateDialog"
      ></task-add-edit>
    </v-card>

    <v-dialog
      v-if="selectedForm != null"
      v-model="renderDialog"
      persistent
      max-width="75%"
    >
      <div>
        <v-card
          v-if="!isEdit"
        >
          <v-card-text>
            <div>
              <v-row
                justify="center"
              >
                <v-col
                  md="auto"
                >
                  <v-btn
                    color="error"
                    @click="updateRenderDialog('render')"
                  >
                    Close
                  </v-btn>
                </v-col>
                <v-col
                  md="auto"
                >
                  <router-link
                    :to="{ name: 'site-patient-chart', params: { patientId: selectedForm.patientId } }"
                  >
                    <v-btn
                      color="success"
                    >
                      View Chart
                    </v-btn>
                  </router-link>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  md="auto"
                >
                  <v-btn
                    color="primary"
                    @click="completeTask(selectedItem), renderDialog = !renderDialog"
                  >
                    Complete
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
        <v-card>
          <form-render
            :form-data="selectedForm.formStyle"
            :form-input-data="selectedForm.formInput"
            :patient-form-d-t-o="selectedForm"
            :is-edit="isEdit"
            :toolbar="isEdit"
            @update-dialog="updateRenderDialog"
            @refetch-data="refreshPage"
          ></form-render>
        </v-card>
      </div>
    </v-dialog>

    <v-card>
      <v-toolbar>
        <!-- search -->
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            :prepend-inner-icon="icons.mdiMagnify"
            class="me-3 mb-4"
          >
          </v-text-field>

          <div class="d-flex align-center flex-wrap">
            <v-btn
              v-model="isTaskFormActive"
              color="primary"
              class="mb-4 me-3"
              @click="updateDialog(null), isEdit = true"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add New Task</span>
            </v-btn>
          </div>
        </v-card-text>

        <!-- Dynamic Type Tabs -->
        <template v-slot:extension>
          <v-tabs
            v-model="currentTab"
            icons-and-text
            grow
            show-arrows
            :next-icon="icons.mdiArrowRight"
            :prev-icon="icons.mdiArrowLeft"
            background-color="primary"
            dark
          >
            <v-tab
              v-for="(type, index) in taskTypeList"
              :key="index"
              @change="currentType = type.display"
            >
              {{ type.display }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="taskListColumns"
        :items="taskListTable"
        :options.sync="options"
        :search="searchQuery"
        :loading="loading"
      >
        <template #[`item.patient.displayName`]="{item}">
          <td
            class="text-xs-left color-corner"
            :class="item.status"
          >
            <router-link
              :to="{ name: 'site-patient-chart', params: { patientId: item.patient.id } }"
            >
              <v-btn
                color="primary"
                text
              >
                {{ item.patient.displayName }}
              </v-btn>
            </router-link>
          </td>
        </template>
        <template #[`item.status`]="{item}">
          <v-btn
            v-if="item.patientForm !== null && item.patientForm !== undefined"
            v-model="renderDialog"
            color="primary"
            text
            @click="selectedItem = item, selectedForm = item.patientForm, updateRenderDialog('render')"
          >
            <v-icon
              class="me-2"
            >
              {{ icons.mdiCheckAll }}
            </v-icon>
            Complete
          </v-btn>
          <span v-else>
            {{ item.status }}
          </span>
        </template>
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="selectedItem = item"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = false, isTaskFormActive = !isTaskFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isEdit = true, isTaskFormActive = !isTaskFormActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="completeTask(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiCheckAll }}
                  </v-icon>
                  <span>Complete</span>
                </v-btn>
              </v-list-item>

              <v-list-item link>
                <v-btn
                  text
                  x-small
                  @click="isDeleteDialogActive = !isDeleteDialogActive"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiFileDocumentEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
  mdiFormTextboxPassword,
  mdiMagnify,
  mdiCheckAll,
} from '@mdi/js'

import taskService from '@/services/TaskService'
import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import useTaskService from '@/store/useTaskService'
import CommonList from '@/store/CommonService'
import FormRender from '@/apps/form/FormRender.vue'
import TaskAddEdit from './TaskAddEdit.vue'

export default {
  components: { DeleteConfirmation, TaskAddEdit, FormRender },
  mounted() {
    this.fetchTaskTypes()
    this.fetchSiteTasksByType()
  },
  setup() {
    const TASK_APP = 'app-task'

    // Register module
    if (!store.hasModule(TASK_APP)) store.registerModule(TASK_APP, taskService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TASK_APP)) store.unregisterModule(TASK_APP)
    })

    const {
      taskListTable,
      taskListColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedRows,
      fetchTaskTypes,
      taskTypeList,
      fetchSiteTasksByType,
      currentType,
      postPatientTask,
    } = useTaskService()

    const { readStatus } = CommonList()

    const isEdit = ref(false)
    const isNew = ref(false)
    const selectedItem = ref({})
    const isDeleteDialogActive = ref(false)
    const isTaskFormActive = ref(false)
    const currentTab = ref(6)
    const renderDialog = ref(false)
    const selectedForm = ref(null)

    return {
      fetchSiteTasksByType,
      fetchTaskTypes,
      postPatientTask,
      taskTypeList,
      taskListTable,
      taskListColumns,
      searchQuery,
      statusFilter,
      loading,
      options,
      selectedRows,
      isTaskFormActive,
      selectedItem,
      isEdit,
      isNew,
      isDeleteDialogActive,
      readStatus,
      currentTab,
      currentType,
      renderDialog,
      selectedForm,
      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiFileDocumentEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
        mdiFormTextboxPassword,
        mdiMagnify,
        mdiCheckAll,
      },
    }
  },

  methods: {
    log(msg, item) {
      console.log(msg, item)
    },
    selectItem(item) {
      this.selectedItem = item
      console.log(this.selectedItem)
    },
    submitDelete(item) {
      console.log(item)
    },
    completeTask() {
      this.selectedItem.status = 'Completed'
      this.postPatientTask(this.selectedItem)

      this.selectedForm = {}
      this.selectedItem = {}
    },
    refreshPage() {
      this.selectedItem = ref()
      this.fetchSiteTasksByType()
      this.$watch('taskListTable', () => {
        console.log(this.taskListTable)
      })
    },
    renderForm(formData) {
      this.selectedForm = formData
      this.selectedItem = formData.formData
    },
    updateRenderDialog(dialog) {
      console.log(this.selectedForm)
      console.log('dialog', dialog, this.renderDialog)
      if (dialog === 'render') {
        if (this.renderDialog) {
          this.selectedForm = {}
          this.selectedItem = {}
        }
        if (this.selectedForm == null) {
          this.renderDialog = false
        }
        if (this.selectedForm != null && this.selectedForm.status !== 'Complete') {
          this.isEdit = true
        }
        this.renderDialog = !this.renderDialog
      }
      if (dialog === 'save') {
        console.log('dialog', dialog, this.renderDialog)
        this.completeTask()
        this.renderDialog = !this.renderDialog
      }
    },
    updateDialog(status) {
      console.log('Status', status)
      console.log('Dialog', this.isTaskFormActive)
      if (status == null) {
        this.selectedItem = {}
      }
      this.isTaskFormActive = !this.isTaskFormActive
      console.log('Selected Item', this.selectedItem)
    },
  },
}
</script>

<style lang="scss">
.color-corner {
  position: relative;
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
}
.New:before {
  border-left: 10px solid #48ff30;
  border-top: 10px solid #48ff30;
}
.Waiting:before {
  border-left: 10px solid #eaff30;
  border-top: 10px solid #eaff30;
}
.Late:before {
  border-left: 10px solid #ff3030;
  border-top: 10px solid #ff3030;
}
.Complete:before {
  border-left: 10px solid #bf1cce;
  border-top: 10px solid #bf1cce;
}
</style>
